@font-face {
  font-family: 'AmazonEmberArabic';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("AmazonEmberArabic_W_Regular.woff2") format("woff2"),
    url("AmazonEmberArabic_W_Regular.woff") format("woff")
}

@font-face {
  font-family: 'AmazonEmberArabic';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url("AmazonEmberArabic_W_Medium.woff2") format("woff2"),
    url("AmazonEmberArabic_W_Medium.woff") format("woff")
}

@font-face {
  font-family: 'AmazonEmberArabic';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("AmazonEmberArabic_W_Bold.woff2") format("woff2"),
    url("AmazonEmberArabic_W_Bold.woff") format("woff")
}

@font-face {
  font-family: 'Inter var experimental';
  font-weight: 100 900;
  font-display: swap;
  font-style: oblique 0deg 10deg;
  src: url("src/assets/fonts/inter/Inter.var.woff2") format("woff2")
}