@import "@amzn/alchemy-components/dist/styles/global.css";

// Override the font declarations in the global.css with our vendored files
@import "assets/fonts/inter/inter.css";
@import "assets/fonts/ember/ember.css";

.fmui-appwrapper {
  font-size: large;
  padding: 0;
}

.language-dropdown {
  width: 300px;
  margin-right: 5px;
}

.fallback-spinner {
  margin: 46%;
}

.tooltip-content {
  max-width: 800px;
  word-wrap: break-word;
  white-space: normal;
  line-height: 1.4;
  padding: 8px;
}

.fallback-spinner-box {
  position: absolute;
  display: flex;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  min-height: 700px;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  color: white;
}

.attribute-name {
  font-family: var(--headers-h1-font-family, var(--font-base-family, Inter, AmazonEmberArabic, Arial, "Helvetica Neue", Helvetica, sans-serif));
  font-weight: 600;
  margin-bottom: 0;
}

.site-filter {
  float: left;
  width: 200px;
}

.data-retrieval-error-text {
  margin-top: 10px;
  width: inherit;
  max-width: inherit;
}

.bread-crumbs {
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  font-size: medium;
}

.summary-card-expand-icon {
  display: inline;
  margin-right: 5px;
}

.summary-card-action {
  border-radius: 3px;
  font-size: initial;
}


#register-mfa-modal #otp-retrieval-details {
  font-size: 85%;
  font-style: italic;
}

#bulk-onboard-page {

  #file-upload-card {
    max-width: 500px;

    p {
      border-bottom: 2px solid #e8e8e9;
      position: relative;
      top: 50%;
      transform: translateY(-50%);
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }

  #results-table {
    --accordion-default-border: none;
    --accordion-default-trigger-font-weight: 400;

    li {
      max-width: none;
    }
  }

}

@media (prefers-color-scheme: dark) {
  .summary-card-action {
    --button-default-hover-background-color: var(--color-base-action);
    --button-default-hover-color: var(--color-base-bright);
  }
}

/**
 * Start of Print OTP Section
 */

@media print {
  @page {
    size: portrait;
    margin-top: 5em;
  }

  #print-otp-pages {
    visibility: visible;

    #bread-crumbs-row {
      display: none;
    }

    #print-instructions {
      display: none;
    }

    .print-otp-page {
      page-break-after: always;
    }
  }

  // Safari does not make background white automatically.
  .b-inactive-background {
    background-color: #FFFF!important;
  }
}

/**
 * End Print OTP Section
 */